<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingText"
    :style="getStyle"
    v-show="
      !loading &&
      data &&
      data.properties &&
      data.properties.filed_content != 'Hide' &&
      !visibility
    "
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 8 : data.description ? 8 : 24"
        style="white-space: nowrap"
      >
        <label
          for="text"
          v-if="
            (hasLabel &&
              data.properties.filed_content != 'Hide' &&
              !data.detailed_view) ||
            data.components.view
          "
          :style="getLabelStyles"
          style="margin-right: 5px; white-space: nowrap"
          >{{ label || data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col :span="2">
        <span
          v-if="isActive || isDataTableField"
          class="setting-icon"
          @click="openSettings"
        >
          <i class="el-icon-s-tools" />
        </span>
      </el-col>
      <el-col :span="6">
        <div class="d-flex" v-if="allRowsData.length > limit">
          <el-input
            placeholder="Search"
            v-model="searchString"
            size="mini"
            class="mr-1"
            clearable
            @input="updatePagination"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button
            type="primary"
            icon="el-icon-arrow-left"
            size="mini"
            @click="pageChange('BACKWARD')"
            :disabled="page <= 1"
          ></el-button>
          <span class="ml-1 mr-1">{{ page }}</span>
          <el-button
            type="primary"
            icon="el-icon-arrow-right"
            size="mini"
            @click="pageChange('FORWARD')"
            :disabled="(allRowsData.length || 0) <= page * limit"
          ></el-button>
        </div>
      </el-col>
    </el-row>
    <span>
      <el-button
        v-if="data.card_view"
        size="mini"
        icon="el-icon-plus"
        :disabled="
          checkEntityVariable('ADD') ? checkEntityVariable('ADD') : readonly()
        "
        @click="addNewRow(-1)"
      ></el-button>
    </span>
    <div
      v-if="data.table_data_view_type == 'table_view'"
      class="table-container"
      v-loading="dataRefresh"
    >
      <el-scrollbar class="scrollbar">
        <table class="content-table">
          <tr>
            <th
              v-if="!isView && !isDetailedView && isShowSelectColumns()"
              class="sticky select-check"
            >
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
              ></el-checkbox>
            </th>
            <th
              v-for="(column, index) in removeHideFields(
                data.data_table_columns
              )"
              :key="index"
              :style="getColumnStyle(column)"
            >
              {{ column.label }}
              <span>
                <el-popover
                  v-if="column.description"
                  placement="top-start"
                  trigger="hover"
                  :content="column.description"
                >
                  <i class="el-icon-info" slot="reference"></i>
                </el-popover>
              </span>
              <span
                v-if="!isView && column.validations.required"
                style="
                  color: red;
                  font-weight: bold;
                  margin-left: 5px;
                  margin-right: 5px;
                "
                >*</span
              >
            </th>
            <th v-if="!isView && !isDetailedView" class="sticky">
              <el-tooltip :content="'Add ' + data.label">
                <el-button
                  v-if="enablePlusButton"
                  class="p-1"
                  size="mini"
                  icon="el-icon-plus"
                  type="primary"
                  :disabled="
                    checkEntityVariable('ADD')
                      ? checkEntityVariable('ADD')
                      : readonly()
                  "
                  @click="addNewRow(-1)"
                ></el-button>
              </el-tooltip>
            </th>
          </tr>
          <tr
            v-for="(rowData, rowIndex) in getRowsData"
            :key="
              rowIndex +
              'data_table' +
              (rowData && rowData.form && rowData.form.keyIndex
                ? rowData.form.keyIndex
                : '')
            "
            ref="newlyAddedRow"
          >
            <th
              v-if="!isView && !isDetailedView && isShowSelectColumns()"
              class="sticky select-check"
            >
              <div class="d-flex">
                <el-checkbox-group
                  v-model="checkList"
                  @change="handleCheckedRowChange"
                >
                  <el-checkbox :label="rowIndex"></el-checkbox>
                </el-checkbox-group>
              </div>
            </th>
            <td
              v-for="(column, colIndex) in removeHideFields(rowData.fields)"
              :key="colIndex + '_main_fields'"
            >
              <component
                v-if="!checkhideFields(column.key, rowIndex)"
                v-bind:is="column.components.execute"
                :data="column"
                :isInDataTable="true"
                :form="rowData.form"
                :fieldsData="fieldsData"
                :is-view="isGetView(column, rowData.form)"
                :dataTableRowIndex="rowIndex"
                :parent-form-data="form"
                :autoFillEntityData="
                  getAutoFillEntityData(column, rowData.form)
                "
                @entityDataUpdated="setDataToEntityVariables"
                @clearEntityFields="unsetEntityVariables"
                @UIUpdated="updateParentUI"
                :readonly="readonly(rowIndex)"
                :rowsData="rowsData"
                :checkIsDisabled="
                  checkIsDisabled(column.key, rowIndex) ||
                  checkSplitFieldReadOnly(column, rowData.form.keyIndex) ||
                  checkAllowEdit(rowData.form, rowIndex)
                "
                :dataTableField="data"
                :filesInfo="filesInfo"
                :fromDataTable="true"
                @refreshTableColumns="refreshTableColumns"
                :refreshEntityFields="
                  refreshEntityColumn &&
                  refreshEntityColumnIndex !== `${rowIndex}_${column.key}`
                "
                :fromDT="true"
                :rowIndex="`${rowIndex}_${column.key}`"
                :suggestedData="getSuggestedRowData(rowIndex)"
                @applyFormRules="
                  rulesEventEmitter(
                    rowData.fields,
                    rowIndex,
                    rowData.form.keyIndex,
                    column.key
                  )
                "
              ></component>
            </td>
            <template>
              <td
                v-show="tempHideFields"
                v-for="(column, colIndex) in hiddenFields(rowData.fields)"
                :key="colIndex + '_hidden_fields'"
              >
                <component
                  v-if="!checkhideFields(column.key, rowIndex)"
                  v-bind:is="column.components.execute"
                  :data="column"
                  :form="rowData.form"
                  :fieldsData="fieldsData"
                  :is-view="isGetView(column, rowData.form)"
                  :dataTableRowIndex="rowIndex"
                  :parent-form-data="form"
                  :autoFillEntityData="
                    getAutoFillEntityData(column, rowData.form)
                  "
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  @UIUpdated="updateParentUI"
                  :readonly="readonly(rowIndex)"
                  :rowsData="rowsData"
                  :fromDataTable="true"
                  @refreshTableColumns="refreshTableColumns"
                  :refreshEntityFields="
                    refreshEntityColumn &&
                    refreshEntityColumnIndex !== `${rowIndex}_${column.key}`
                  "
                  :checkIsDisabled="
                    checkIsDisabled(column.key, rowIndex) ||
                    checkSplitFieldReadOnly(column, rowData.form.keyIndex)
                  "
                  :dataTableField="data"
                  :suggestedData="getSuggestedRowData(rowIndex)"
                  @applyFormRules="
                    rulesEventEmitter(
                      rowData.fields,
                      rowIndex,
                      rowData.form.keyIndex,
                      column.key
                    )
                  "
                ></component>
              </td>
            </template>
            <th v-if="!isView && !isDetailedView" class="sticky">
              <div class="d-flex">
                <el-tooltip :content="'Remove ' + data.label">
                  <el-button
                    class="p-1"
                    size="mini"
                    icon="el-icon-minus"
                    @click="removeRow(rowIndex)"
                    :disabled="
                      checkEntityVariable('EDIT')
                        ? checkEntityVariable('EDIT')
                        : readonly()
                    "
                  ></el-button>
                </el-tooltip>
                <!-- <el-button
                size="mini"
                icon="el-icon-plus"
                :disabled="
                  checkEntityVariable('ADD')
                    ? checkEntityVariable('ADD')
                    : readonly()
                "
                @click="addNewRow(-1)"
                ></el-button> -->
                <el-tooltip :content="'Add ' + data.label">
                  <el-button
                    class="p-1"
                    v-if="data.is_entity_variable"
                    size="mini"
                    icon="el-icon-plus"
                    @click="splitRow(rowIndex)"
                    :disabled="
                      checkEntityVariable('EDIT')
                        ? checkEntityVariable('EDIT')
                        : readonly()
                    "
                  ></el-button>
                </el-tooltip>
                <!-- <el-button
                  size="mini"
                  icon="el-icon-plus"
                  :disabled="
                    checkEntityVariable('ADD')
                      ? checkEntityVariable('ADD')
                      : readonly()
                  "
                  @click="addNewRow(-1)"
                ></el-button> -->
                <!-- <el-button
                  v-if="data.is_entity_variable"
                  size="mini"
                  icon="el-icon-plus"
                  @click="splitRow(rowIndex)"
                  :disabled="
                    checkEntityVariable('EDIT')
                      ? checkEntityVariable('EDIT')
                      : readonly()
                  "
                ></el-button> -->
              </div>
            </th>
          </tr>
        </table>
      </el-scrollbar>
      <div class="instruction-button" v-if="data.properties.enable_add_button">
        <el-button
          class="p-1"
          size="mini"
          icon="el-icon-plus"
          type="primary"
          :disabled="
            checkEntityVariable('ADD') ? checkEntityVariable('ADD') : readonly()
          "
          @click="addNewRow(-1)"
        >
          {{ data.properties.add_button_name || data.label }}</el-button
        >
      </div>
    </div>
    <div
      v-else-if="data.table_data_view_type == 'card_view'"
      class="table-container"
    >
      <div v-if="getRowsData.length == 0" class="nodata-collapsable">
        <el-button
          size="mini"
          icon="el-icon-plus"
          type="primary"
          :disabled="
            checkEntityVariable('ADD') ? checkEntityVariable('ADD') : readonly()
          "
          @click="addNewRow(-1)"
          >Add Data</el-button
        >
      </div>
      <el-scrollbar v-else class="scrollbar">
        <div style="width: 100%" class="fixed-table">
          <div
            style="
              display: flex;
              justify-content: space-evenly;
              flex-wrap: wrap;
              scrollbar-width: none;
            "
          >
            <div
              v-for="(rowData, rowIndex) in getRowsData"
              :key="
                rowIndex +
                'data_table' +
                (rowData && rowData.form && rowData.form.keyIndex
                  ? rowData.form.keyIndex
                  : '')
              "
              ref="newlyAddedRow"
              class="card-style"
            >
              <div class="aligned-buttons">
                <el-button
                  size="mini"
                  icon="el-icon-plus"
                  :disabled="
                    checkEntityVariable('ADD')
                      ? checkEntityVariable('ADD')
                      : readonly()
                  "
                  @click="addNewRow(-1)"
                ></el-button>
                <el-button
                  size="mini"
                  icon="el-icon-minus"
                  @click="removeRow(rowIndex)"
                  :disabled="
                    checkEntityVariable('EDIT')
                      ? checkEntityVariable('EDIT')
                      : readonly()
                  "
                ></el-button>
              </div>
              <div
                v-for="(column, colIndex) in removeHideFields(rowData.fields)"
                :key="colIndex + '_main_fields'"
                :style="{ width: column.width + 'px', margin: '10px' }"
              >
                <component
                  v-if="!checkhideFields(column.key, rowIndex)"
                  v-bind:is="column.components.execute"
                  :data="column"
                  :isInDataTable="true"
                  :form="rowData.form"
                  :fieldsData="fieldsData"
                  :is-view="isGetView(column, rowData.form)"
                  :dataTableRowIndex="rowIndex"
                  :parent-form-data="form"
                  :autoFillEntityData="
                    getAutoFillEntityData(column, rowData.form)
                  "
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  @UIUpdated="updateParentUI"
                  :readonly="readonly(rowIndex)"
                  :rowsData="rowsData"
                  :checkIsDisabled="
                    checkIsDisabled(column.key, rowIndex) ||
                    checkSplitFieldReadOnly(column, rowData.form.keyIndex)
                  "
                  :dataTableField="data"
                  :suggestedData="getSuggestedRowData(rowIndex)"
                  @applyFormRules="
                    rulesEventEmitter(
                      rowData.fields,
                      rowIndex,
                      rowData.form.keyIndex,
                      column.key
                    )
                  "
                ></component>
              </div>
              <template>
                <div
                  v-show="tempHideFields"
                  v-for="(column, colIndex) in hiddenFields(rowData.fields)"
                  :key="colIndex + '_hidden_fields'"
                  :style="{ width: column.width + 'px' }"
                >
                  <component
                    v-if="!checkhideFields(column.key, rowIndex)"
                    v-bind:is="column.components.execute"
                    :data="column"
                    :form="rowData.form"
                    :fieldsData="fieldsData"
                    :is-view="isGetView(column, rowData.form)"
                    :dataTableRowIndex="rowIndex"
                    :parent-form-data="form"
                    :autoFillEntityData="
                      getAutoFillEntityData(column, rowData.form)
                    "
                    @entityDataUpdated="setDataToEntityVariables"
                    @clearEntityFields="unsetEntityVariables"
                    @UIUpdated="updateParentUI"
                    :readonly="readonly(rowIndex)"
                    :rowsData="rowsData"
                    :checkIsDisabled="
                      checkIsDisabled(column.key, rowIndex) ||
                      checkSplitFieldReadOnly(column, rowData.form.keyIndex)
                    "
                    :dataTableField="data"
                    :suggestedData="getSuggestedRowData(rowIndex)"
                    @applyFormRules="
                      rulesEventEmitter(
                        rowData.fields,
                        rowIndex,
                        rowData.form.keyIndex,
                        column.key
                      )
                    "
                  ></component>
                </div>
              </template>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div v-else class="data-table-view">
      <div v-if="getRowsData.length == 0" class="nodata-collapsable">
        <p>Click to add items</p>
        <el-button
          size="mini"
          icon="el-icon-plus"
          :disabled="
            checkEntityVariable('ADD') ? checkEntityVariable('ADD') : readonly()
          "
          @click="addNewRow(-1)"
        ></el-button>
      </div>
      <el-collapse
        v-model="activeNames"
        @change="handleCollapseChange"
        accordion
        v-else
      >
        <el-collapse-item
          v-for="(rowData, rowIndex) in getRowsData"
          :key="rowIndex"
          :name="rowIndex.toString()"
          :title="`Row ${rowIndex + 1}`"
        >
          <template slot="title">
            {{ getHeaderTitle(rowData) }}
            <el-button
              v-if="!data.hide_add_remove_buttons"
              size="mini"
              class="ml-1"
              icon="el-icon-plus"
              :disabled="
                checkEntityVariable('ADD')
                  ? checkEntityVariable('ADD')
                  : readonly()
              "
              @click.stop="handleCollapseChange"
              @click="addNewRow(-1)"
            ></el-button>
            <el-button
              v-if="!data.hide_add_remove_buttons"
              size="mini"
              icon="el-icon-minus"
              @click="removeRow(rowIndex)"
              @click.stop="handleCollapseChange"
              :disabled="
                checkEntityVariable('EDIT')
                  ? checkEntityVariable('EDIT')
                  : readonly()
              "
            ></el-button>
            <div
              v-if="!isView && !isDetailedView && isShowSelectColumns()"
              class="sticky select-check"
            >
              <el-checkbox-group
                v-model="checkList"
                @change="handleCheckedRowChange"
              >
                <el-checkbox :label="rowIndex"></el-checkbox>
              </el-checkbox-group>
            </div>
          </template>
          <div
            v-for="(column, colIndex) in removeHideFields(rowData.fields)"
            :key="colIndex + '_main_fields'"
            :style="{ width: column.width + 'px', margin: '10px' }"
          >
            <span :style="getLabelStyles">{{ column.label }}</span>
            <component
              v-if="!checkhideFields(column.key, rowIndex)"
              v-bind:is="column.components.execute"
              :data="column"
              :isInDataTable="false"
              :form="rowData.form"
              :fieldsData="fieldsData"
              :is-view="isGetView(column, rowData.form)"
              :dataTableRowIndex="rowIndex"
              :parent-form-data="form"
              :autoFillEntityData="getAutoFillEntityData(column, rowData.form)"
              @entityDataUpdated="setDataToEntityVariables"
              @clearEntityFields="unsetEntityVariables"
              @UIUpdated="updateParentUI"
              :readonly="readonly(rowIndex)"
              :rowsData="rowsData"
              :checkIsDisabled="
                checkIsDisabled(column.key, rowIndex) ||
                checkSplitFieldReadOnly(column, rowData.form.keyIndex)
              "
              :dataTableField="data"
              @applyFormRules="
                rulesEventEmitter(
                  rowData.fields,
                  rowIndex,
                  rowData.form.keyIndex,
                  column.key
                )
              "
            ></component>
          </div>
          <template>
            <div
              v-show="tempHideFields"
              v-for="(column, colIndex) in hiddenFields(rowData.fields)"
              :key="colIndex + '_hidden_fields'"
              :style="{ width: column.width + 'px' }"
            >
              <p>{{ column.label }}</p>
              <component
                v-if="!checkhideFields(column.key, rowIndex)"
                v-bind:is="column.components.execute"
                :data="column"
                :form="rowData.form"
                :fieldsData="fieldsData"
                :is-view="isGetView(column, rowData.form)"
                :dataTableRowIndex="rowIndex"
                :parent-form-data="form"
                :autoFillEntityData="
                  getAutoFillEntityData(column, rowData.form)
                "
                @entityDataUpdated="setDataToEntityVariables"
                @clearEntityFields="unsetEntityVariables"
                @UIUpdated="updateParentUI"
                :readonly="readonly(rowIndex)"
                :rowsData="rowsData"
                :checkIsDisabled="
                  checkIsDisabled(column.key, rowIndex) ||
                  checkSplitFieldReadOnly(column, rowData.form.keyIndex)
                "
                :dataTableField="data"
                @applyFormRules="
                  rulesEventEmitter(
                    rowData.fields,
                    rowIndex,
                    rowData.form.keyIndex,
                    column.key
                  )
                "
              ></component>
            </div>
          </template>
          <div v-if="!isView && !isDetailedView" class="sticky">
            <div class="d-flex">
              <!-- <el-button
                size="mini"
                icon="el-icon-plus"
                :disabled="
                  checkEntityVariable('ADD')
                    ? checkEntityVariable('ADD')
                    : readonly()
                "
                @click="addNewRow(-1)"
                ></el-button> -->
              <el-tooltip :content="'Add ' + data.label">
                <el-button
                  class="p-1"
                  v-if="data.is_entity_variable"
                  size="mini"
                  icon="el-icon-plus"
                  @click="splitRow(rowIndex)"
                  :disabled="
                    checkEntityVariable('EDIT')
                      ? checkEntityVariable('EDIT')
                      : readonly()
                  "
                ></el-button>
              </el-tooltip>
              <!-- <el-button
                  size="mini"
                  icon="el-icon-plus"
                  :disabled="
                    checkEntityVariable('ADD')
                      ? checkEntityVariable('ADD')
                      : readonly()
                  "
                  @click="addNewRow(-1)"
                ></el-button> -->
              <!-- <el-button
                  v-if="data.is_entity_variable"
                  size="mini"
                  icon="el-icon-plus"
                  @click="splitRow(rowIndex)"
                  :disabled="
                    checkEntityVariable('EDIT')
                      ? checkEntityVariable('EDIT')
                      : readonly()
                  "
                ></el-button> -->
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { dataTableAllowedExcecuteFields } from "./index";
import { bus } from "../../../main";
import moment from "moment-timezone";
import { dataTableAllowedViewFields } from "./../formComponentsView/index";
import { mapGetters } from "vuex";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  name: "DataTableExecute",
  props: [
    "visibility",
    "hasLabel",
    "field",
    "data",
    "isActive",
    "fieldsData",
    "form",
    "isView",
    "isDataTableField",
    "entityDataList",
    "parentField",
    "parentFormData",
    "entityDataId",
    "entityDataExists",
    "savingData",
    "hiddenColumns",
    "checkAllFieldsDisabled",
    "disabledColumnsFields",
    "selectedTableRows",
    "filesInfo",
    "fromEntityViews",
    "suggestedData",
    "isFromFormBuilder",
  ],
  mixins: [TemplateBuilderHelper, CustomDashboardConfig, userPermissionsHelper],
  components: {
    ...dataTableAllowedExcecuteFields,
    ...dataTableAllowedViewFields,
  },
  data() {
    return {
      enablePlusButton: true,
      label: null,
      activeNames: [],
      newIndices: [],
      newIndex: null,
      checkList: [],
      checkAll: false,
      isIndeterminate: false,
      showLabel: false,
      rowsData: [],
      childFieldsData: [],
      aggregateFunctionsBasedOnTable: [],
      rowLoading: false,
      parent: null,
      fieldRules: [],
      showTemplateFields: [],
      hideFields: [],
      disabledFields: [],
      auto_fill_field: null,
      auto_fill_data: null,
      weekDays: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      previousFormData: null,
      previousAutoFillData: {},
      previousDefaultData: {},
      filters: [],
      loading: false,
      loadingText: "Auto filling data",
      autoFillEntityData: null,
      entityDataMapping: {},
      tempHideFields: false,
      dataRefresh: true,
      searchString: "",
      limit: 40,
      page: 1,
      searchWarning: false,
      entityVariableForm: {},
      outsideFields: [],
      dataTableFieldsObject: {},
      auto_fill_fields: [],
      new_added_row: [],
      entityDatas: {},
      mapEntityVariables: {},
      showRowSelection: false,
      doNotApplyTableData: false,
      refreshEntityColumn: false,
      refreshEntityColumnIndex: "",
    };
  },
  beforeDestroy() {
    bus.$off("data_table_update");
  },
  async mounted() {
    this.data.showLabel = false;
    this.childFieldsData = this.data.data_table_columns;
    this.dataTableFieldsObject = {};
    this.childFieldsData.map((e) => {
      this.dataTableFieldsObject[e.key] = e;
    });
    if (!this.isView) {
      this.getEntityReadOnlyFieldsData();
    }
    this.aggregateFunctionsBasedOnTable = this.fieldsData.filter((field) => {
      if (field.fields_type == "TABLE_FIELDS") {
        return true;
      }
    });
    //mapping entity variables with there parent data
    this.mappingEntityVariables(this.data?.data_table_columns || []);
    if (this.form[this.data.key]) {
      if (!this.isView) {
        await this.fetchEntityFieldsData();
      } else {
        this.dataRefresh = false;
      }
      this.form[this.data.key].forEach((dt) => {
        let fields = [
          ...this.data.data_table_columns.map((e) => {
            if (e.styles) {
              e.styles.labelStyle = "hide";
            }
            if (e.properties) {
              e.properties.hideLabel = true;
            }

            return e;
          }),
        ];
        let e = this.mapDefaultValues(fields, dt, false, {}, true);
        let element = {
          form: e,
          fields,
        };
        if (
          this.rowsData.findIndex(
            (d) =>
              d?.form?.keyIndex == e.keyIndex &&
              !d?.form?.newlyAddedData &&
              !e.newlyAddedData
          ) == -1
        ) {
          this.rowsData.push(element);
        }
      });
    } else {
      if (
        this.data.default_rows &&
        (!this.form[this.data.key] || !this.form[this.data.key].length)
      ) {
        for (let i = 0; i < this.data.default_rows; i++) {
          this.addNewRow(-1);
        }
      }
      this.dataRefresh = false;
    }
    // this.setNewRowElements();
    this.data.styles.labelStyle;
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.is_entity_variable) {
      this.parent = this.fieldsData.find(
        (e) => e.key == this.data.relationship_key
      );
    }
    if (this.data?.rules) {
      this.fieldRules = this.data.rules;
    }
    if (
      this.data?.data_table_columns &&
      !this.data.is_entity_variable &&
      (!this.entityDataId ||
        (this.isFromFormBuilder &&
          (!this.form[this.data.key] || !this.form[this.data.key].length))) &&
      !this.$route.query.newData &&
      !this.isView
    ) {
      this.auto_fill_field = this.data.data_table_columns.find(
        (e) => e.data_table_auto_fill
      );
      if (this.auto_fill_field) {
        this.fetchEntityFieldData();
      }
      this.auto_fill_fields = this.data.data_table_columns.filter(
        (e) => e.data_table_field_auto_fill
      );
      if (this.auto_fill_fields && this.auto_fill_fields.length) {
        let allFilterEntityIds = [];
        this.auto_fill_fields.map((e) => {
          if (e.data_table_filters && e.data_table_filters.length) {
            e.data_table_filters.map((fil) => {
              if (
                fil.type == "ENTITY" &&
                !allFilterEntityIds.includes(fil.entity_id)
              ) {
                allFilterEntityIds.push(fil.entity_id);
              }
            });
          }
        });
        await Promise.all(
          allFilterEntityIds.map(async (e_id) => {
            let params = {
              entity_id: e_id,
              template_fields_data: [],
              filters: [],
              relationship: null,
              limit: 500,
              page: 1,
            };
            // await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
            try {
              const response = await postAPICall(
                "POST",
                "/entities-data/entity/data",
                params
              );
              if (response && response.data) {
                this.entityDatas[e_id] = response.data;
              }
            } catch (error) {
              console.error("mounted", error);
            }
          })
        );
        this.autoFillPreferredFields();
      }
    }
    if (
      this.entityVariableTableColumns &&
      this.entityVariableTableColumns.length
    ) {
      this.outsideFields = Array.from(
        new Set(
          this.entityVariableTableColumns.flatMap((e) => e.relationship_key)
        )
      );
    }
    bus.$on(
      "entityDataUpdated",
      (selectedData, data, label, changed, dataTableRowIndex) => {
        // this.setNewRowElements();
        this.setDataToEntityVariables(
          selectedData,
          data,
          label,
          changed,
          dataTableRowIndex
        );
      }
    );
    bus.$on("out-side-entity-update", (data, parent, label, changed) => {
      if (
        this.outsideFields.indexOf(parent.key) !== -1 &&
        this.rowsData &&
        this.rowsData.length
      ) {
        this.applyOutSideEntityvariables(data, parent, label, changed);
      }
    });
    bus.$on("data_table_update", ({ key, data }) => {
      if (this.data.key == key) {
        this.rowsData = [];
        let fields = [
          ...this.data.data_table_columns.map((e) => {
            if (e.styles) {
              e.styles.labelStyle = "hide";
            }
            if (e.properties) {
              e.properties.hideLabel = true;
            }
            return e;
          }),
        ];
        data.forEach((e) => {
          this.rowsData.push({
            form: e,
            fields,
          });
        });
      }
    });
    if (
      this.data?.properties &&
      Object.keys(this.data.properties).includes("enable_plus_button")
    ) {
      this.enablePlusButton = this.data.properties.enable_plus_button;
    }
    this.activeNames = this.getRowsData.map((_, index) => index.toString());
  },

  updated: function () {
    this.$nextTick(() => {
      this.updateParentUI();
    });
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("entitiesData", ["getEntitiesDataForDataTable"]),
    ...mapGetters("companyTemplates", [
      "getAllCompanyTemplatesData",
      "getTemplateDataTempVariable",
    ]),
    getStyle() {
      return this.getElementStyle;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      return borderStyle;
    },
    getRowsData() {
      return this.applyPagination(
        this.filterDataByProperties(
          this.rowsData.filter((e) => e.form && !e.form.newlyAddedData) || [],
          this.searchString,
          this.removeHideFields(this.data.data_table_columns).flatMap(
            (e) => e.key
          )
        )
      );
    },
    allRowsData() {
      return this.filterDataByProperties(
        this.rowsData.filter((e) => e.form && !e.form.newlyAddedData) || [],
        this.searchString,
        this.removeHideFields(this.data.data_table_columns).flatMap(
          (e) => e.key
        )
      );
    },
    isDetailedView() {
      return (
        this.data.is_entity_variable && this.parent && this.parent.detailed_view
      );
    },
    entityVariableTableColumns() {
      const entityVariablesExisted = this.data.data_table_columns.filter(
        (e) => {
          if (
            e.input_type == "ENTITY_VARIABLE" &&
            this.isEntityFromOutside(e)
          ) {
            e.outside_entity_field = this.isEntityFromOutside(e);
            return true;
          }
          return false;
        }
      );
      return entityVariablesExisted;
    },
  },
  methods: {
    formatDate(date, fieldType) {
      if (fieldType === "DATE_TIME_RANGE") {
        const d = new Date(date);
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");
        const year = d.getFullYear();
        const hours = String(d.getHours()).padStart(2, "0");
        const minutes = String(d.getMinutes()).padStart(2, "0");
        const seconds = String(d.getSeconds()).padStart(2, "0");
        return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
      } else if (fieldType === "DATE") {
        const d = new Date(date);
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");
        const year = d.getFullYear();
        return `${month}-${day}-${year}`;
      }
    },
    getHeaderTitle(rowData) {
      const selectedHeaderTitle = this.data.selected_header_title;
      const column = rowData.fields.find(
        (field) => field.label === selectedHeaderTitle
      );
      const value = rowData.form[column.key];
      if (column.inputType === "DATE_TIME_RANGE") {
        if (
          column &&
          rowData.form[column.key] &&
          rowData.form[column.key].length > 0
        ) {
          if (Array.isArray(value) && value.length === 2) {
            return (
              this.formatDate(value[0], column.inputType) +
              " To " +
              this.formatDate(value[1])
            );
          }
          return value;
        }
      } else if (column.inputType === "DATE") {
        if (!value) {
          return "";
        }
        return this.formatDate(rowData.form[column.key], column.inputType);
      } else if (column.inputType === "CHECKBOX_GROUP") {
        if (!value) {
          return "";
        }
        return value.join(",");
      }
      return column ? rowData.form[column.key] : "Click to add a new accordion";
    },
    handleCollapseChange() {
      setTimeout(() => {
        this.$emit("onNewRowAdded", {});
        //let the parent component know when a row is added so that it updates height for below elements
      }, 300);
    },
    getSuggestedRowData(index) {
      return this.suggestedData?.[this.data.key]?.[index] || {};
    },
    refreshTableColumns(data) {
      this.refreshEntityColumn = false;
      setTimeout(() => {
        this.refreshEntityColumnIndex = data.rowIndex;
        this.refreshEntityColumn = true;
      });
    },
    getColumnStyle(column) {
      let params = {};
      if (column?.width) {
        params["minWidth"] = column.width + "px";
      }
      if (column?.styles?.labelAlignments) {
        params["textAlign"] = column.styles.labelAlignments;
      }
      return params;
    },
    mappingEntityVariables(fields) {
      fields.forEach((data) => {
        if (data.input_type == "ENTITY_VARIABLE") {
          let [templateId, key] = data.global_variable_entity_field.split("#");
          let tempData;
          if (
            this.getTemplateDataTempVariable &&
            this.getTemplateDataTempVariable[templateId] &&
            templateId
          ) {
            tempData = JSON.parse(
              JSON.stringify(this.getTemplateDataTempVariable[templateId])
            );
          }
          if (tempData?.sections?.[0]?.fields) {
            let selectedField = tempData?.sections?.[0]?.fields.find(
              (f) => f.key == key
            );
            if (selectedField) {
              this.mapEntityVariables[data.key] = selectedField;
            }
          }
        }
      });
    },
    applyOutSideEntityvariables(data, parent, label, changed) {
      this.rowsData = this.rowsData.map((row) => {
        let form = row.form || {};
        row.form = this.applyEntityVariableData(
          this.entityVariableTableColumns,
          form,
          data,
          parent,
          changed
        );
        return row;
      });
    },
    applyEntityVariableData(fields, form, data, parent, changed) {
      fields.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          (field.inputType == "ENTITY" ||
            field.input_type == "ENTITY_VARIABLE") &&
          (field.field_assignable == "read_only" || !form[field.key] || changed)
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                let existedIds = (form[field.key] || []).flatMap(
                  (dt) => dt.parentDataId
                );
                (data || []).forEach((e) => {
                  if (
                    e?.entityData?.[templateId]?.[key] &&
                    existedIds.indexOf(e._id) == -1
                  ) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach((dt) => {
                          dataValues.push({
                            ...dt,
                            ...{
                              parentDataId: e._id,
                              parentDataName: e.parentDataName,
                            },
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (existedIds.length) {
                  dataValues = [...dataValues, ...(form[field.key] || [])];
                }
                if (field.inputType == "DATA_TABLE") {
                  this.$set(form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    this.$set(form, field.key, avg);
                  } else if (field.variable_action == "LOWEST") {
                    this.$set(form, field.key, min);
                  } else {
                    this.$set(form, field.key, result);
                  }
                }
              }
            } else {
              if (
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];

                if (field.inputType == "ENTITY") {
                  valueName = data.entityData[templateId][key + "/name"];
                  if (typeof value == "object" && value.length) {
                    this.$set(form, field.key + "/default", value);
                  } else {
                    this.$set(form, field.key + "/default", [value]);
                  }
                }
              }

              if (form[field.key]) {
                form[field.key] = value;
                if (valueName) {
                  form[field.key + "/name"] = valueName;
                }
              } else {
                this.$set(form, field.key, value);
                if (valueName) {
                  this.$set(form, field.key + "/name", valueName);
                }
              }
            }
          }
        }
      });
      return form;
    },
    filterDataByProperties(data, searchString, properties) {
      if (!searchString || !properties.length || searchString.length < 2) {
        return data;
      }
      const searchLowerCase = searchString.toLowerCase();

      return data.filter((item) => {
        return properties.some((property) => {
          const propertyValue =
            item && item.form && item.form[property]
              ? item.form[property + "/name"]
                ? item.form[property + "/name"].toString().toLowerCase()
                : item.form[property].toString().toLowerCase()
              : "";
          return propertyValue.includes(searchLowerCase);
        });
      });
    },
    updatePagination() {
      if (
        this.searchString &&
        this.searchString.length == 1 &&
        !this.searchWarning
      ) {
        this.$message({
          message: "Please enter more than 2 letters to search",
          type: "warning",
        });
        this.searchWarning = true;
      }
      if (!this.searchString || this.searchString.length < 3) {
        this.page = 1;
      }
    },
    pageChange(change) {
      if (change == "FORWARD") {
        this.page++;
      } else {
        this.page--;
      }
    },
    applyPagination(rowsData) {
      return rowsData.slice(
        (this.page - 1) * this.limit,
        this.limit + (this.page - 1) * this.limit
      );
    },
    scrollToNewRow(index) {
      if ("scrollBehavior" in document.documentElement.style) {
        const row = this.$refs.newlyAddedRow[index];
        if (row) {
          row.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }
    },
    async getEntityReadOnlyFieldsData() {
      if (this.data.is_entity_variable) {
        let entityFields = (
          this.removeHideFields(this.childFieldsData) || []
        ).filter(
          (e) => e.inputType == "ENTITY" && e.field_assignable == "read_only"
        );
        await Promise.all(
          entityFields.map(async (el) => {
            let params = {
              entity_id: el.entity_id,
              template_fields_data: [],
              filters: [],
              relationship: null,
              limit: 500,
              page: 1,
            };
            // await this.$store.dispatch(
            //   "entities/fetchEntityRecordsForTable",
            //   params
            // );
            try {
              const response = await postAPICall(
                "POST",
                "/entities-data/entity/data",
                params
              );
              if (response && response.data) {
                this.entityDataMapping[el.entity_id] = {
                  ...response,
                  ...{ update: true },
                };
              }
            } catch (error) {
              console.error("getEntityReadOnlyFieldsData", error);
              // Optionally, handle the error by updating the UI or showing a message to the user
            }

            return el;
          })
        );
      }
    },
    getAutoFillEntityData(field, form) {
      if (
        this.auto_fill_field?.key == field.key &&
        form[field.key + "@autofill"]
      ) {
        return this.autoFillEntityData;
      }
      if (
        (this.checkFieldIsReadOnly(field) ||
          this.checkIsIndependentField(field)) &&
        this.entityDataMapping &&
        this.entityDataMapping[field.entity_id] &&
        !this.entityDataId
      ) {
        return this.entityDataMapping[field.entity_id];
      }
      if (
        this.data.is_entity_variable &&
        field.inputType == "ENTITY" &&
        field.field_assignable == "read_only" &&
        field?.entity_id &&
        this.entityDataMapping &&
        this.entityDataMapping[field.entity_id]
      ) {
        return this.entityDataMapping[field.entity_id];
      }
      return null;
    },
    checkSplitFieldReadOnly(field, keyIndex) {
      if (field?.show_in_split) {
        let splitField = this.rowsData.filter(
          (e) => e?.form?.keyIndex == keyIndex && e?.form?.newlyAddedData
        );
        if (splitField && splitField.length) {
          return true;
        }
        return false;
      }
      return false;
    },
    isGetView(field) {
      if (
        field.input_type == "ENTITY_VARIABLE" &&
        field?.field_assignable == "read_only" &&
        // form[field.key + "@autofill"] &&
        !this.data.is_entity_variable
      ) {
        return true;
      } else if (field?.entityDataExists) {
        return true;
      }
      return this.isView;
    },
    async fetchEntityFieldData() {
      this.loading = true;
      this.loadingText = "Fetching preferred " + this.auto_fill_field.label;
      this.rowsData = [];
      this.form[this.data.key] = [];
      this.filters = this.mapFilters(this.auto_fill_field.filters);
      let fields = this.getTableMappedFields();
      let params = {
        entity_id: this.auto_fill_field.entity_id,
        template_fields_data: [],
        filters: this.mapDateFilters(
          this.removeOptionalFilters(this.filters || [], false)
        ),
        //filters: this.mapDateFilters(this.filters || []).filter(
        //   (e) => !e.preferred_filter
        // ),
        relationship: {},
        limit: 20,
        page: 1,
      };
      if (this.auto_fill_field?.entity_sort) {
        let [order, by] = this.auto_fill_field.entity_sort.split("/");
        params.sortBy = by;
        params.sortOrder = order;
      }
      this.filterLoading = true;
      // await Promise.all([
      //   this.$store.dispatch("entities/fetchEntityRecordsForTable", params),
      // ]);
      try {
        const response = await postAPICall(
          "POST",
          "/entities-data/entity/data",
          params
        );
        if (response?.data) {
          let dataOptions = [],
            primaryField = response.selectedEntity.primaryFields[0];
          this.autoFillEntityData = [];
          this.autoFillEntityData = response;
          if (this.autoFillEntityData.data.length) {
            this.autoFillEntityData = {
              ...this.autoFillEntityData,
              ...{ update: true },
            };
          }
          let firstRowFormData = {};
          const entityVariablesExisted = this.entityVariableTableColumns;
          if (entityVariablesExisted && entityVariablesExisted.length) {
            firstRowFormData = await this.getFirstRowFormData(
              entityVariablesExisted
            );
          }
          this.rowsData = [];
          this.form[this.data.key] = [];
          this.autoFillEntityData.data.forEach((data) => {
            if (data?.entityData) {
              let form = {
                [this.auto_fill_field.key]: data._id,
                [this.auto_fill_field.key + "/name"]: this.getPrimaryName(
                  primaryField,
                  data
                ),
                [this.auto_fill_field.key + "@autofill"]: true,
                ["keyIndex"]: (Math.random() + 1).toString(36).substring(7),
                ...firstRowFormData,
              };
              fields.forEach((field) => {
                if (
                  field.relationship_key == this.auto_fill_field.key &&
                  (field.field_assignable == "read_only" ||
                    field.inputType == "ENTITY" ||
                    !form[field.key])
                ) {
                  if (
                    (field.global_variable_entity_field &&
                      field.global_variable_entity_field.includes("~")) ||
                    field.variable_action === "RESOURCE_AVERAGE"
                  ) {
                    // we need to skip assign data part we if the field is relationship field
                    // because we are setting entity data. but entity data don't have relation data by default. it makes value as null.
                    return;
                  }
                  form[field.key + "@autofill"] = true;
                  if (field.global_variable_entity_field) {
                    let templateId = "";
                    let key = "";
                    let value = "";
                    let valueName = "";
                    if (field.global_variable_entity_field.includes("#")) {
                      templateId =
                        field.global_variable_entity_field.split("#")[0];
                      key = field.global_variable_entity_field.split("#")[1];
                    } else {
                      templateId = field.global_variable_entity_field;
                    }
                    if (parent?.allow_multiple) {
                      if (data.length) {
                        let dataValues = [],
                          result = 0,
                          min;
                        let existedIds = (
                          form && form[field.key] && form[field.key].length
                            ? form[field.key]
                            : []
                        ).flatMap((dt) => dt.parentDataId);
                        (data || []).forEach((e) => {
                          if (
                            e?.entityData?.[templateId]?.[key] &&
                            existedIds.indexOf(e._id) == -1
                          ) {
                            if (field.inputType == "DATA_TABLE") {
                              if (e.entityData[templateId][key].length) {
                                e?.entityData[templateId][key].forEach((dt) => {
                                  dataValues.push({
                                    ...dt,
                                    ...{
                                      parentDataId: e._id,
                                      parentDataName: e.parentDataName,
                                    },
                                  });
                                });
                              }
                            } else if (
                              this.isNumber(e.entityData[templateId][key])
                            ) {
                              let v = e.entityData[templateId][key];
                              if (
                                field.variable_action == "SUM" ||
                                field.variable_action == "AVERAGE"
                              ) {
                                result = result + parseInt(v);
                              } else if (
                                field.variable_action == "HIGHEST" &&
                                v > result
                              ) {
                                result = parseInt(v);
                              } else if (field.variable_action == "LOWEST") {
                                if (v < min || min == undefined) {
                                  min = parseInt(v);
                                }
                              }
                            }
                          }
                        });
                        if (existedIds.length) {
                          dataValues = [
                            ...dataValues,
                            ...(form[field.key] || []),
                          ];
                        }
                        if (field.inputType == "DATA_TABLE") {
                          this.$set(form, field.key, dataValues);
                        } else {
                          if (field.variable_action == "AVERAGE") {
                            let avg = result / data.length;
                            this.$set(form, field.key, avg);
                          } else if (field.variable_action == "LOWEST") {
                            this.$set(form, field.key, min);
                          } else {
                            this.$set(form, field.key, result);
                          }
                        }
                      }
                    } else {
                      if (
                        data.entityData &&
                        data.entityData[templateId] &&
                        data.entityData[templateId][key]
                      ) {
                        value = data.entityData[templateId][key];

                        if (field.inputType == "ENTITY") {
                          valueName =
                            data.entityData[templateId][key + "/name"];
                          if (typeof value == "object" && value.length) {
                            this.$set(form, field.key + "/default", value);
                          } else {
                            this.$set(form, field.key + "/default", [value]);
                          }
                        }
                      }

                      if (form[field.key]) {
                        if (data.entityData[templateId][key + "_code"]) {
                          form[field.key + "_code"] =
                            data.entityData[templateId][key + "_code"];
                        }
                        form[field.key] = value;
                        if (valueName) {
                          form[field.key + "/name"] = valueName;
                        }
                      } else {
                        this.$set(form, field.key, value);
                        if (valueName) {
                          this.$set(form, field.key + "/name", valueName);
                        }
                      }

                      if (field.global_variable_pay_field) {
                        //logic for Payment Variable fields
                        this.$set(
                          form,
                          field.global_variable_entity_select_type_field_key +
                            "_" +
                            field.global_variable_pay_field,
                          data.entityData[templateId][
                            field.global_variable_entity_select_type_field_key +
                              "_" +
                              field.global_variable_pay_field
                          ]
                        );
                      }
                      if (
                        field.inputType == "PAY_BUTTON" ||
                        field.input_type == "PAY_BUTTON"
                      ) {
                        // pay button data set block

                        form[field.key + "_info"] =
                          data.entityData[templateId][
                            field.global_variable_entity_select_type_field_key +
                              "_info"
                          ];
                        form[field.key + "_history"] =
                          data.entityData[templateId][
                            field.global_variable_entity_select_type_field_key +
                              "_history"
                          ];
                      }
                    }
                  }
                }
              });
              form = this.mapDefaultValues(
                this.data.data_table_columns,
                form,
                false,
                {},
                true
              );
              this.rowsData.push({
                fields,
                form,
              });
              dataOptions.push(form);
            }
          });
          this.$set(this.form, this.data.key, dataOptions);
          setTimeout(() => {
            this.$emit("onNewRowAdded", {});
          }, 300);
          setTimeout(() => {
            this.$set(
              this.form,
              this.data.key + "/Onloaded",
              !this.form[this.data.key + "/Onloaded"]
            );
          }, 1000);
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async autoFillPreferredFields() {
      this.loading = true;
      this.loadingText = "Auto Filling preferred fields...";
      let fields = this.getTableMappedFields();
      this.rowsData = [];
      for (const e of this.auto_fill_fields) {
        if (e.input_type === "DATE") {
          let toBeFilledData = [];
          if (e.data_table_field_auto_fill_type === "range") {
            if (this.form[e.selected_auto_fill_fields.range]) {
              toBeFilledData = await this.getAllBetweenData(
                {
                  from: moment(this.form[e.selected_auto_fill_fields.range][0]),
                  to: moment(this.form[e.selected_auto_fill_fields.range][1]),
                },
                "DATE",
                e.data_table_filters,
                e.selected_auto_fill_fields.frequency_step,
                e.selected_auto_fill_fields.frequency_type,
                e.data_table_filter_query
              );
            }
          } else if (e.data_table_field_auto_fill_type === "custom") {
            if (
              this.form[e.selected_auto_fill_fields.from] &&
              this.form[e.selected_auto_fill_fields.to] &&
              moment(this.form[e.selected_auto_fill_fields.from]) <
                moment(this.form[e.selected_auto_fill_fields.to])
            ) {
              toBeFilledData = await this.getAllBetweenData(
                {
                  from: moment(this.form[e.selected_auto_fill_fields.from]),
                  to: moment(this.form[e.selected_auto_fill_fields.to]),
                },
                "DATE",
                e.data_table_filters,
                e.selected_auto_fill_fields.frequency_step,
                e.selected_auto_fill_fields.frequency_type,
                e.data_table_filter_query
              );
            }
          } else if (e.data_table_field_auto_fill_type === "frequency") {
            if (
              this.form[e.selected_auto_fill_fields.from] &&
              e.selected_auto_fill_fields.to &&
              e.selected_auto_fill_fields.type
            ) {
              let type =
                e.selected_auto_fill_fields.type !== "week"
                  ? e.selected_auto_fill_fields.type
                  : "days";
              let number =
                e.selected_auto_fill_fields.type !== "week"
                  ? e.selected_auto_fill_fields.to
                  : e.selected_auto_fill_fields.to * 7;
              let toDate = moment(
                this.form[e.selected_auto_fill_fields.from]
              ).add(number, type);
              toBeFilledData = await this.getAllBetweenData(
                {
                  from: moment(this.form[e.selected_auto_fill_fields.from]),
                  to: toDate,
                },
                "DATE",
                e.data_table_filters,
                e.selected_auto_fill_fields.frequency_step,
                e.selected_auto_fill_fields.frequency_type,
                e.data_table_filter_query
              );
            }
          }
          let rows = [];
          toBeFilledData.map((d) => {
            let form = {};
            form[e.key] = d;
            form["keyIndex"] = (Math.random() + 1).toString(36).substring(7);
            form = this.mapDefaultValues(
              this.data.data_table_columns,
              form,
              false,
              this.form,
              true
            );
            let row = {
              fields: fields,
              form: form,
            };
            this.rowsData.push(row);
            rows.push(row);
          });
          this.$set(this.form, this.data.key, rows);
          setTimeout(() => {
            this.$emit("onNewRowAdded", {});
          }, 300);
        } else if (e.input_type === "WEEKDAYS" && !e.allow_multiple) {
          let autoFilledField = this.fieldsData.find(
            (field) => field.key == e.selected_auto_fill_fields.from
          );
          if (autoFilledField && autoFilledField.input_type == "WEEKDAYS") {
            if (this.form[e.selected_auto_fill_fields.from].length) {
              let rows = [];
              this.form[e.selected_auto_fill_fields.from].map((day) => {
                let form = {};
                form[e.key] = day;
                form["keyIndex"] = (Math.random() + 1)
                  .toString(36)
                  .substring(7);
                form = this.mapDefaultValues(
                  this.data.data_table_columns,
                  form,
                  false,
                  this.form,
                  true
                );
                let row = {
                  fields: fields,
                  form: form,
                };
                this.rowsData.push(row);
                rows.push(row);
              });
              this.$set(this.form, this.data.key, rows);
              setTimeout(() => {
                this.$emit("onNewRowAdded", {});
              }, 300);
            }
          } else {
            this.rowsData.map((row, index) => {
              if (row.form[e.selected_auto_fill_fields.from]) {
                let day = moment(
                  row.form[e.selected_auto_fill_fields.from]
                ).day();
                switch (day) {
                  case 0:
                    this.rowsData[index].form[e.key] = "SUN";
                    break;
                  case 1:
                    this.rowsData[index].form[e.key] = "MON";
                    break;
                  case 2:
                    this.rowsData[index].form[e.key] = "TUE";
                    break;
                  case 3:
                    this.rowsData[index].form[e.key] = "WED";
                    break;
                  case 4:
                    this.rowsData[index].form[e.key] = "THU";
                    break;
                  case 5:
                    this.rowsData[index].form[e.key] = "FRI";
                    break;
                  case 6:
                    this.rowsData[index].form[e.key] = "SAT";
                    break;
                }
              }
            });
            this.$set(this.form, this.data.key, this.rowsData);
          }
        }
      }
      this.$emit("onDataAdded");
      this.loading = false;
    },
    async getAllBetweenData(
      range,
      type,
      filters = [],
      step = "days",
      number = 1,
      query = "AND"
    ) {
      let data = [];
      if (type == "DATE") {
        if (step == "week") {
          number = number * 7;
          step = "days";
        }
        for (
          let currentDate = moment(range.from);
          currentDate <= range.to;
          currentDate.add(number, step)
        ) {
          data.push(moment(currentDate));
        }
        let weekDays = {
          MON: 1,
          TUE: 2,
          WED: 3,
          THU: 4,
          FRI: 5,
          SAT: 6,
          SUN: 0,
        };
        let weekDaysFieldData = [];
        let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (filters.length) {
          let toBeReturnedData = [];
          data.map((dt) => {
            let allFiltersResponse = filters.map((filter) => {
              if (this.form[filter.field] || filter.type == "ENTITY") {
                switch (filter.operator) {
                  case "EQUALS":
                    if (filter.type == "DATE") {
                      return (
                        moment(dt).format("DD-MM-YYYY") ==
                        moment(this.form[filter.field]).format("DD-MM-YYYY")
                      );
                    } else if (filter.type == "WEEKDAYS_SINGLE") {
                      return (
                        moment(dt).day() == weekDays[this.form[filter.field]]
                      );
                    }
                    break;
                  case "NOT_EQUALS":
                    if (filter.type == "DATE") {
                      return (
                        moment(dt).format("DD-MM-YYYY") !==
                        moment(this.form[filter.field]).format("DD-MM-YYYY")
                      );
                    } else if (filter.type == "WEEKDAYS_SINGLE") {
                      return (
                        moment(dt).day() !== weekDays[this.form[filter.field]]
                      );
                    }
                    break;
                  case "BETWEEN":
                    return (
                      moment(dt).format("DD-MM-YYYY") >=
                        moment(this.form[filter.field][0]).format(
                          "DD-MM-YYYY"
                        ) &&
                      moment(dt).format("DD-MM-YYYY") <=
                        moment(this.form[filter.field][1]).format("DD-MM-YYYY")
                    );
                  case "NOT_BETWEEN":
                    return (
                      moment(dt).format("DD-MM-YYYY") <
                        moment(this.form[filter.field][0]).format(
                          "DD-MM-YYYY"
                        ) ||
                      moment(dt).format("DD-MM-YYYY") >
                        moment(this.form[filter.field][1]).format("DD-MM-YYYY")
                    );
                  case "IN":
                    if (filter.type == "WEEKDAYS") {
                      weekDaysFieldData = this.form[filter.field].map(
                        (f) => weekDays[f]
                      );
                      return weekDaysFieldData.includes(moment(dt).day());
                    } else if (filter.type == "ENTITY") {
                      let selectedFieldDatas = [];
                      if (this.entityDatas[filter.entity_id]) {
                        this.entityDatas[filter.entity_id].map((eData) => {
                          if (
                            eData &&
                            eData.entityData &&
                            Object.keys(eData.entityData).includes(
                              filter.field.split("#")[0]
                            ) &&
                            eData.entityData[filter.field.split("#")[0]][
                              filter.field.split("#")[1]
                            ]
                          ) {
                            selectedFieldDatas.push(
                              moment(
                                eData.entityData[filter.field.split("#")[0]][
                                  filter.field.split("#")[1]
                                ]
                              )
                                .tz(currentTimezone.toString())
                                .format("DD-MM-YYYY")
                            );
                          }
                        });
                      }
                      return selectedFieldDatas.includes(
                        moment(dt).format("DD-MM-YYYY")
                      );
                    }
                    break;
                  case "NOT_IN":
                    if (filter.type == "WEEKDAYS") {
                      weekDaysFieldData = this.form[filter.field].map(
                        (f) => weekDays[f]
                      );
                      return !weekDaysFieldData.includes(moment(dt).day());
                    } else if (filter.type == "ENTITY") {
                      let selectedFieldDatas = [];
                      if (this.entityDatas[filter.entity_id]) {
                        this.entityData[filter.entity_id].map((eData) => {
                          if (
                            eData &&
                            eData.entityData &&
                            Object.keys(eData.entityData).includes(
                              filter.field.split("#")[0]
                            ) &&
                            eData.entityData[filter.field.split("#")[0]][
                              filter.field.split("#")[1]
                            ]
                          ) {
                            selectedFieldDatas.push(
                              moment(
                                eData.entityData[filter.field.split("#")[0]][
                                  filter.field.split("#")[1]
                                ]
                              )
                                .tz(currentTimezone.toString())
                                .format("DD-MM-YYYY")
                            );
                          }
                        });
                      }
                      return !selectedFieldDatas.includes(
                        moment(dt).format("DD-MM-YYYY")
                      );
                    }
                    break;
                }
              }
            });
            if (query == "AND" && !allFiltersResponse.includes(false)) {
              toBeReturnedData.push(dt);
            } else if (query == "OR" && allFiltersResponse.includes(true)) {
              toBeReturnedData.push(dt);
            }
          });
          return toBeReturnedData;
        } else {
          return data;
        }
      }
    },
    mapFilters(filters) {
      return filters.map((e) => {
        if (
          e &&
          e.data_source == "self_field" &&
          e.value_field &&
          !e.primary_filter
        ) {
          e.value = this.form[e.value_field];
          if (this.parentFormData && this.parentFormData[e.value_field]) {
            e.value = this.parentFormData[e.value_field];
          }
          e.data_type = "RELATION";
        } else if (
          e &&
          e.data_source == "from_date" &&
          e.data_type == "WEEKDAYS" &&
          e.value_field
        ) {
          let key = e.value_field.includes("#")
            ? e.value_field.split("#")[1]
            : e.value_field;
          if (this.form[key]) {
            let date = new Date(this.form[key]).getDay();
            e.value = [this.weekDays[date]];
          } else {
            e.value = [];
          }
        } else if (
          e.field == "UNIQUE" &&
          this.data?.parent_entityId &&
          this.data?.template_id
        ) {
          e.entity_id =
            this.data?.parent_entityId +
            "#" +
            this.data?.template_id +
            "#" +
            this.data.key;
          if (this.data?.parent_entityDataId) {
            e.parent_entityDataId = this.data.parent_entityDataId;
          }
          if (e.unique_field) {
            if (e.unique_field.includes("#")) {
              e.unique_value = this.form[e.unique_field.split("#")[1]];
            } else {
              e.unique_value = this.form[e.unique_field];
            }
          }
          if (e.limit_field && e.limit_field.includes("#")) {
            e.current_limit_field = parseInt(
              this.form[e.limit_field.split("#")[1]]
            );
          }
        } else if (e.data_source == "TEMPLATE" && e.value_field) {
          let v = this.form[e.value_field];
          if (this.parentFormData && this.parentFormData[e.value_field]) {
            v = this.parentFormData[e.value_field];
          }
          if (
            (e.operator == "in" || e.operator == "nin") &&
            typeof v != "object"
          ) {
            e.value = [v];
          } else if (e.data_type == "NUMBER") {
            e.value = parseInt(v || 0);
          } else {
            e.value = v;
          }
        }
        return e;
      });
    },
    checkIsDisabled(key, rowIndex) {
      if (this.checkAllFieldsDisabled) {
        return true;
      }
      if (
        this.disabledColumnsFields &&
        this.disabledColumnsFields.find(
          (e) => e.includes("#") && e.split("#")[1] == key
        )
      ) {
        return true;
      }
      return this.disabledFields.find((e) => e == key + rowIndex)
        ? true
        : false;
    },
    checkhideFields(key, rowIndex) {
      return this.hideFields.find((e) => e == key + "$" + rowIndex)
        ? true
        : false;
    },
    rulesEventEmitter(fields, rowIndex, keyIndex) {
      if (this.entityDataExists) {
        return;
      }
      let changedDataIndex = (this.form[this.data.key] || []).findIndex(
        (e) => e.keyIndex == keyIndex && !e.newlyAddedData
      );
      if (changedDataIndex > -1) {
        if (
          this.form[this.data.key][changedDataIndex] &&
          this.getRowsData[rowIndex].form
          // !this.deepEqual(
          //   this.form[this.data.key][changedDataIndex],
          //   this.getRowsData[rowIndex].form
          // )
        ) {
          this.applyRulesOnDataTableFields(fields, this.fieldRules, rowIndex);
          this.$emit("applyFormRules");
        }
      }
    },
    splitRow(index) {
      this.addNewRow(index + 1);
    },
    checkEntityVariable(action) {
      if (this.data?.is_entity_variable) {
        if (action == "ADD") {
          if (this.data?.alow_data_table_add) {
            return false;
          }
        } else if (action == "EDIT") {
          if (this.data?.alow_data_table_edit) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    hiddenFields(fields) {
      let allowedFields = ["ENTITY", "FORMULA", "AGGREGATE_FUNCTION"];
      return fields.filter((data) => {
        if (allowedFields.indexOf(data.inputType) === -1) {
          return false;
        }
        if (data.inputType == "ENTITY") {
          let childField = fields.find(
            (e) =>
              e.input_type == "ENTITY_VARIABLE" &&
              e.relationship_key == data.key
          );
          if (!childField) {
            return false;
          }
        }
        if (data?.properties?.filed_content === "Hide") {
          return true;
        }
        if (
          this.data?.is_entity_variable &&
          data?.show_in_split &&
          !data.field_operation
        ) {
          return true;
        }
        return false;
      });
    },
    removeHideFields(fields) {
      return fields.filter((data) => {
        //check entity variables are required
        if (
          data?.input_type == "ENTITY_VARIABLE" &&
          this.mapEntityVariables[data.key]
        ) {
          if (this.mapEntityVariables[data.key].validations) {
            data["validations"] = this.mapEntityVariables[data.key].validations;
          }
        }
        if (data?.properties?.filed_content != "Hide") {
          if (
            this.data?.is_entity_variable &&
            data?.show_in_split &&
            !data.field_operation
          ) {
            return false;
          }
          return (this.hiddenColumns || []).find(
            (e) => e == this.data.key + "#" + data.key
          )
            ? false
            : true;
        }
      });
    },
    updateParentUI() {
      this.$emit("onNewRowAdded", {});
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "HTML_CONTENT") {
          // this.isList = true;
          this.data.content = this.getGlobalVariableById.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },

    async setDataToEntityVariables(data, parent, label, changed, index = null) {
      if (index != null && index > -1) {
        this.updateSingleRowEntityFieldsRows(
          data,
          parent,
          label,
          changed,
          index
        );
      } else {
        this.updateAllEntityFieldsRows(data, parent, label, changed);
      }
    },

    async unsetEntityVariables(parent, index = null) {
      if (index != null && index > -1) {
        if (!this.rowsData[index] || !this.rowsData[index].form) {
          return;
        }
        this.childFieldsData.forEach((field) => {
          if (field.relationship_key == parent.key) {
            this.$set(this.rowsData[index].form, field.key, "");
          }
        });
      } else {
        this.childFieldsData.forEach((field) => {
          if (field.relationship_key == parent.key) {
            this.rowsData.forEach((row, i) => {
              this.$set(this.rowsData[i].form, field.key, "");
            });
          }
        });
      }
    },

    updateAllEntityFieldsRows(data, parent, label, changed) {
      // let entityId = data && data._id ? data._id : null;
      if (label) {
        this.rowsData.forEach((row, index) => {
          this.$set(this.rowsData[index].form, parent.key + "/name", label);
        });
      }
      this.childFieldsData.forEach((field) => {
        if (
          field &&
          parent &&
          field.relationship_key == parent.key &&
          // data?.entityData &&
          (field.field_assignable == "read_only" || changed)
        ) {
          if (
            (field.global_variable_entity_field &&
              field.global_variable_entity_field.includes("~")) ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }

            if (
              data &&
              data.entityData &&
              data.entityData[templateId] &&
              data.entityData[templateId][key]
            ) {
              value = data.entityData[templateId][key];

              if (field.inputType == "ENTITY") {
                valueName = data.entityData[templateId][key + "/name"];
                this.rowsData.forEach((row, index) => {
                  this.$set(this.rowsData[index].form, field.key + "/default", [
                    value,
                  ]);
                });
              }
            }

            this.rowsData.forEach((row, index) => {
              this.$set(this.rowsData[index].form, field.key, value);
            });

            if (valueName) {
              this.rowsData.forEach((row, index) => {
                this.$set(
                  this.rowsData[index].form,
                  field.key + "/name",
                  valueName
                );
              });
            }
          }
        }
      });

      // this.rowsData.forEach((row, index) => {
      //   if (!this.rowsData[index].form.parent_data) {
      //     this.rowsData[index].form.parent_data = {};
      //   }
      //   if (entityId) {
      //     this.rowsData[index].form.parent_data[entityId] = data;
      //   } else {
      //     this.rowsData[index].form.parent_data = {};
      //   }
      // });
    },

    updateSingleRowEntityFieldsRows(data, parent, label, changed, index) {
      if (!this.rowsData[index] || !this.rowsData[index].form) {
        return;
      }
      // let entityId = data && data._id ? data._id : null;
      if (label) {
        this.$set(this.rowsData[index].form, parent.key + "/name", label);
      }
      this.childFieldsData.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          (field.field_assignable == "read_only" ||
            (changed &&
              (field.overwrite_on_change ||
                !this.rowsData[index].form?.[field.key])))
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                (data || []).forEach((e) => {
                  if (e?.entityData?.[templateId]?.[key]) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach((dt) => {
                          dataValues.push({
                            ...dt,
                            ...{
                              parentDataId: e._id,
                              parentDataName: e.parentDataName,
                            },
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (field.inputType == "DATA_TABLE") {
                  value = dataValues;
                  // this.$set(this.rowsData[index].form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    // this.$set(this.rowsData[index].form, field.key, avg);
                    value = avg;
                  } else if (field.variable_action == "LOWEST") {
                    // this.$set(this.rowsData[index].form, field.key, min);
                    value = min;
                  } else {
                    // this.$set(this.rowsData[index].form, field.key, result);
                    value = result;
                  }
                }
              }
            } else {
              if (
                data &&
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                if (
                  data.entityData[templateId][key + "@autofill"] &&
                  this.rowsData[index]?.form
                ) {
                  value = this.rowsData[index].form[key];
                  valueName = this.rowsData[index].form[key + "/name"];
                } else {
                  value = data.entityData[templateId][key];

                  if (field.inputType == "ENTITY") {
                    valueName = data.entityData[templateId][key + "/name"];
                    this.$set(
                      this.rowsData[index].form,
                      field.key + "/default",
                      [value]
                    );
                  }
                }
              }
            }

            this.$set(this.rowsData[index].form, field.key, value);
            if(this.form?.[this.data.key]?.[index]){
              this.$set(this.form[this.data.key][index], field.key, value);
            }

            if (valueName) {
              this.$set(
                this.rowsData[index].form,
                field.key + "/name",
                valueName
              );
              // this.rowsData.forEach((row, index) => {
              //   this.$set(
              //     this.rowsData[index].form,
              //     field.key + "/name",
              //     valueName
              //   );
              // });
            }
          }
        }
      });

      // if (!this.rowsData[index].form.parent_data) {
      //   this.rowsData[index].form.parent_data = {};
      // }
      // if (entityId) {
      //   this.rowsData[index].form.parent_data[entityId] = data;
      // } else {
      //   this.rowsData[index].form.parent_data = {};
      // }
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    getTableMappedFields() {
      let fields = [...this.data.data_table_columns];

      return fields.map((f) => {
        let e = JSON.parse(JSON.stringify(f));
        if (e.styles) {
          e.styles.labelStyle = "hide";
          e.styles.font = {};
        } else {
          e.styles = {
            labelStyle: "hide",
            font: {},
          };
        }
        if (e.properties) {
          e.properties = {
            ...e.properties,
            ...{
              hideLabel: true,
            },
          };
        } else {
          e.properties = {
            hideLabel: true,
          };
        }
        if (e.source) {
          e.source = "";
        }
        return e;
      });
    },
    checkAllowEdit(data, index) {
      const keysArray = Object.keys(data);
      const length = keysArray.length;
      const isNewRow = this.newIndices.includes(index);

      if (
        length > 1 &&
        !this.data.allow_edit_delete &&
        !(index == this.newIndex) &&
        !isNewRow
      ) {
        return true; // Disable editing for existing rows
      } else {
        return false; // Allow editing for existing rows
      }
    },
    async addNewRow(index) {
      let fields = this.getTableMappedFields();
      let scrollToIndex;
      let formData;

      if (index > -1) {
        this.rowsData.splice(index + 1, 0, {
          form: {
            ...JSON.parse(JSON.stringify(this.rowsData[index].form)),
            newlyAddedData: true,
          },
          fields,
        });
        scrollToIndex = index + 1; // Scroll to the newly inserted row
      } else {
        (formData = await this.prepareFormWithValues()),
          this.rowsData.push({
            form: formData,
            fields,
          });
        scrollToIndex = this.rowsData.length - 1; // Scroll to the last (newly added) row
        this.newIndex = scrollToIndex;
        this.newIndices.push(this.rowsData.length);
      }
      setTimeout(() => {
        this.$emit("onNewRowAdded", {});

        // Scroll to the newly added row
        this.scrollToNewRow(scrollToIndex);
      }, 300);

      this.new_added_row.push({
        form: formData,
        fields,
      });
    },
    removeRow(index) {
      const Newkeys = this.new_added_row.map((e) => e.form.keyIndex);

      if (
        this.data.allow_deletion ||
        Object.keys(this.rowsData[index].form).length === 1
      ) {
        this.rowsData.splice(index, 1);
        setTimeout(() => {
          this.$emit("onNewRowAdded", {});
        }, 300);
      } else {
        if (Newkeys.length > 0) {
          const newRows = this.rowsData.filter((e) =>
            Newkeys.includes(e.form.keyIndex)
          );
          if (newRows.length > 0) {
            this.rowsData.splice(index, 1);
            setTimeout(() => {
              this.$emit("onNewRowAdded", {});
            }, 300);
          } else {
            this.$notify.error({
              title: "Warning",
              message: "You do not have permission to remove rows",
              type: "warning",
            });
          }
        } else {
          this.$notify.error({
            title: "Warning",
            message: "You do not have permission to remove rows",
            type: "warning",
          });
        }
      }
    },

    async prepareFormWithValues() {
      const entityVariablesExisted = this.entityVariableTableColumns;
      if (entityVariablesExisted.length) {
        let form = {};

        if (this.rowsData.length) {
          this.data.data_table_columns.filter((e) => {
            if (
              e.input_type == "ENTITY_VARIABLE" &&
              e.selected_enity_field_data &&
              e.selected_enity_field_data.input_type != "ENTITY"
            ) {
              form[e.key] = this.rowsData[0].form[e.key];
            }
          });
        } else {
          let firstRowFormData = await this.getFirstRowFormData(
            entityVariablesExisted
          );
          form = this.mapDefaultValues(
            this.data.data_table_columns,
            firstRowFormData,
            false,
            {},
            true
          );
        }
        form["keyIndex"] = (Math.random() + 1).toString(36).substring(7);
        return form;
      }
      let form = this.mapDefaultValues(
        this.data.data_table_columns,
        {},
        false,
        {},
        true
      );
      return {
        ...form,
        ["keyIndex"]: (Math.random() + 1).toString(36).substring(7),
      };
    },
    readonly() {
      if (this.checkAllFieldsDisabled) {
        return true;
      }
      //If it's an entity varaible, have to check access

      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "DISABLED"
      ) {
        return true;
      }
    },
    isEntityFromOutside(field) {
      return this.fieldsData.find(
        (e) => e.inputType == "ENTITY" && e.key == field.relationship_key
      );
    },

    async getFirstRowFormData(entityVariablesExisted) {
      let entityConfigurations = [];

      let form = {};
      entityVariablesExisted.forEach((e) => {
        if (this.form[e.outside_entity_field.key]) {
          //  fetch only value fields

          let record = {
            entity_id: e.outside_entity_field.entity_id,
            entity_data_id: this.form[e.outside_entity_field.key],
          };

          if (e.global_variable_entity_field_template_id) {
            record.template_ids = [e.global_variable_entity_field_template_id];
          } else {
            record.template_ids = [];
          }
          entityConfigurations.push(record);
        }
      });

      let finalUniqueData = [];

      for (let i = 0; i < entityConfigurations.length; i++) {
        let entityDataId = entityConfigurations[i].entity_data_id;

        let index = finalUniqueData.findIndex(
          (e) => e.entity_data_id == entityDataId
        );

        if (index == -1) {
          finalUniqueData.push(entityConfigurations[i]);
        } else {
          finalUniqueData[index].template_ids = [
            ...finalUniqueData[index].template_ids,
            ...entityConfigurations[i].template_ids,
          ];
        }
      }

      if (finalUniqueData.length) {
        // we need to fetch records for first row from api

        let entitiesData = await this.entitiesDataForFirstRow(finalUniqueData);

        if (entitiesData.length) {
          entityVariablesExisted.forEach((field) => {
            let fieldParentEntitData = entitiesData.find((entitiyData) => {
              return (
                entitiyData._id == this.form[field.outside_entity_field.key]
              );
            });

            if (fieldParentEntitData) {
              if (
                fieldParentEntitData["entityData"] &&
                fieldParentEntitData["entityData"][
                  field.global_variable_entity_field_template_id
                ] &&
                fieldParentEntitData["entityData"][
                  field.global_variable_entity_field_template_id
                ][field.global_variable_entity_select_type_field_key]
              ) {
                form[field.key] =
                  fieldParentEntitData["entityData"][
                    field.global_variable_entity_field_template_id
                  ][field.global_variable_entity_select_type_field_key];
              }
            }
          });
        }
        return form;
      }
      return form;
    },

    async entitiesDataForFirstRow(finalUniqueData) {
      try {
        this.rowLoading = true;

        await this.$store.dispatch(
          "entitiesData/fetchEntitiesDataForDataTable",
          {
            fields: finalUniqueData,
          }
        );

        return [...this.getEntitiesDataForDataTable];
      } catch (err) {
        console.error("entitiesDataForFirstRow", err);
      } finally {
        this.rowLoading = false;
      }
    },
    async setNewRowElements() {
      // if (!this.entityDataList || !this.entityDataList.length) {
      //   this.rowsData = [];
      //   return;
      // }
      if (this.form[this.data.key] && this.form[this.data.key].length) {
        this.doNotApplyTableData = true;
        this.rowsData = [];
        let tableData = [];
        if (
          this.data.data_table_filters &&
          this.data.data_table_filters.length &&
          !this.readonly()
        ) {
          tableData = this.checkFilterConditions(
            this.form[this.data.key],
            this.data.data_table_filters,
            this.data.data_table_filter_query || "AND",
            this.data.data_table_columns
          );
        } else {
          tableData = this.form[this.data.key];
        }
        tableData.forEach((dt) => {
          let fields = [
            ...this.data.data_table_columns.map((e) => {
              if (e.styles) {
                e.styles.labelStyle = "hide";
              }

              if (e.properties) {
                e.properties.hideLabel = true;
              }

              return e;
            }),
          ];
          let e = this.mapDefaultValues(fields, dt, false, {}, true);
          let element = {
            form: e,
            fields,
          };
          if (
            this.rowsData.findIndex(
              (d) =>
                d?.form?.keyIndex == e.keyIndex &&
                !d?.form?.newlyAddedData &&
                !e.newlyAddedData
            ) == -1
          ) {
            this.rowsData.push(element);
          }
        });
      } else {
        this.rowsData = [];
      }
      setTimeout(() => {
        this.doNotApplyTableData = false;
        this.$emit("onNewRowAdded", {});
      }, 300);
    },
    checkIsIndependentField(data) {
      if (!data?.filters || !data.filters.length) {
        return true;
      } else {
        let selfTableFilter = (data.filters || []).find(
          (filter) =>
            filter?.data_source == "self_field" &&
            filter?.data_type == "RELATION" &&
            filter.value_field &&
            this.fieldsData.findIndex((e) => e.key == filter.value_field) === -1
        );
        if (selfTableFilter) {
          return false;
        }
        return true;
      }
    },
    checkFieldIsReadOnly(data) {
      if (
        (data.field_assignable === "read_only" && data.is_entity_variable) ||
        (data &&
          data.properties &&
          data.properties.filed_content === "DISABLED") ||
        (data &&
          data.properties &&
          data.properties.filed_content === "READ ONLY")
      ) {
        return true;
      }
      return false;
    },
    async fetchEntityFieldsData() {
      this.dataRefresh = true;
      await Promise.all(
        this.removeHideFields(this.data.data_table_columns).map(
          async (data) => {
            if (
              data.input_type == "ENTITY" &&
              (this.checkFieldIsReadOnly(data) ||
                this.checkIsIndependentField(data))
            ) {
              let filters = [];
              if (
                this.checkIsIndependentField(data) &&
                data.filters &&
                !this.checkFieldIsReadOnly(data)
              ) {
                filters = this.mapFilters(data.filters);
              }
              let params = {
                entity_id: data.entity_id,
                template_fields_data: [],
                filters: filters,
                relationship: null,
                limit: 500,
                page: 1,
              };
              // await this.$store.dispatch(
              //   "entities/fetchEntityRecordsForTable",
              //   params
              // );
              try {
                const response = await postAPICall(
                  "POST",
                  "/entities-data/entity/data",
                  params
                );
                if (response && response.data) {
                  this.entityDataMapping[data.entity_id] = {
                    ...response,
                    ...{ update: true },
                  };
                }
              } catch (error) {
                console.error("fetchEntityFieldsData", error);
              }
            }
            return data;
          }
        )
      );
      this.dataRefresh = false;
    },
    isShowSelectColumns() {
      if (this.checkHasDuplicateAction() && this.$route?.query?.dataId) {
        return true;
      }
      return false;
    },
    checkHasDuplicateAction() {
      let actionButtonFields = this.fieldsData.filter(
        (e) => e.input_type == "ACTION_BUTTON"
      );
      let flag = false;
      actionButtonFields.map((field) => {
        if (flag) {
          return;
        }
        (field.actions || []).map((action) => {
          if (flag) {
            return;
          }
          if (
            action.action_button_action_type == "CREATE_DUPLICATE" &&
            action.selected_fields.includes(
              `${field.template_id}#${this.data.key}`
            )
          ) {
            flag = true;
          }
        });
      });
      return flag;
    },
    handleCheckAllChange(val) {
      this.checkList = val
        ? Array.from(Array(this.getRowsData.length).keys())
        : [];
      this.selectedTableRows[`${this.data.template_id}#${this.data.key}`] = [
        ...this.checkList,
      ];
      this.isIndeterminate = false;
    },
    handleCheckedRowChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.getRowsData.length;
      this.selectedTableRows[`${this.data.template_id}#${this.data.key}`] = [
        ...this.checkList,
      ];
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.getRowsData.length;
    },
    handleSuggestedDataChange() {
      let data = this.suggestedData?.[this.data.key];
      let suggestedDataFields = ["ENTITY"];
      let newDataArray = [];
      data.map((row) => {
        let obj = {};
        Object.keys(row).map((e) => {
          let field = this.dataTableFieldsObject[e];
          if (
            (field || e == "keyIndex") &&
            !suggestedDataFields.includes(field?.input_type)
          ) {
            obj[e] = row[e];
          }
        });
        newDataArray.push(obj);
      });
      this.$set(this.form, this.data.key, newDataArray);
    },
  },
  watch: {
    rowsData: {
      async handler() {
        if (
          // !this.data.is_entity_variable &&
          !this.doNotApplyTableData
          // &&
          // !this.checkDatatableDatasEqual(
          //   (this.form[this.data.key] || []).map((e) =>
          //     JSON.parse(JSON.stringify(e))
          //   ),
          //   this.rowsData.map((e) => JSON.parse(JSON.stringify(e.form)))
          // )
        ) {
          this.$emit("updateTableData", {
            key: this.data.key,
            data: this.rowsData.map((e) => JSON.parse(JSON.stringify(e.form))),
          });
        }
      },
      deep: true,
    },
    form: {
      async handler(data) {
        if (
          !this.savingData &&
          // this.data.is_entity_variable &&
          !this.checkDatatableDatasEqual(
            (data[this.data.key] || []).map((e) =>
              JSON.parse(JSON.stringify(e))
            ),
            this.rowsData.map((e) => JSON.parse(JSON.stringify(e.form)))
          )
        ) {
          this.setNewRowElements();
          this.setDataToEntityVariables(
            this.entityDataList,
            this.parentField,
            this.form[this.data.relationship_key + "/name"],
            true
          );
        }
        let currentData = {};
        this.filters.map((e) => {
          if (e.value_field) {
            currentData[e.value_field] = data[e.value_field];
          }
        });
        if (
          JSON.stringify(this.previousFormData) !==
            JSON.stringify(currentData) &&
          this.auto_fill_field
        ) {
          this.previousFormData = { ...currentData };
          this.fetchEntityFieldData();
        }
        let currentAutoFillData = {};
        this.auto_fill_fields.map((e) => {
          currentAutoFillData[e.selected_auto_fill_fields.from] =
            data[e.selected_auto_fill_fields.from];
          currentAutoFillData[e.selected_auto_fill_fields.to] =
            data[e.selected_auto_fill_fields.to];
          currentAutoFillData[e.selected_auto_fill_fields.range] =
            data[e.selected_auto_fill_fields.range];
          if (e.data_table_filters && e.data_table_filters.length) {
            e.data_table_filters.map((filter) => {
              currentAutoFillData[filter.field] = data[filter.field];
            });
          }
        });
        this.data.data_table_columns.map((e) => {
          if (e && e.default_mapped_field) {
            currentAutoFillData[e.default_mapped_field] =
              data[e.default_mapped_field];
          }
        });
        if (
          JSON.stringify(currentAutoFillData) !==
            JSON.stringify(this.previousAutoFillData) &&
          !this.data.is_entity_variable &&
          this.auto_fill_fields.length
        ) {
          this.previousAutoFillData = {
            ...JSON.parse(JSON.stringify(currentAutoFillData)),
          };
          this.autoFillPreferredFields();
        }
        //form data updated
      },
      deep: true,
    },
    suggestedData: {
      async handler(data) {
        if (data[this.data.key]) {
          this.handleSuggestedDataChange();
        }
      },
      deep: true,
    },
    // entityDataList: {
    //   async handler() {
    //     this.setNewRowElements();
    //     this.setDataToEntityVariables(
    //       this.entityDataList,
    //       this.parentField,
    //       this.form[this.data.relationship_key + "/name"],
    //       true
    //     );
    //   },
    // },
  },
};
</script>

<style lang="scss">
.responsive-font {
  font-size: 1.2vw;
}
</style>

<style scoped>
.table-container {
  max-width: 100%;
  border-radius: 10px;
  /* Add a horizontal scrollbar if content overflows */
}

.table-container::v-deep .el-scrollbar__view {
  /* Your styles for el-scrollbar__view go here */
  border-radius: 10px;
  overflow-x: auto;
  scrollbar-width: thin;
}

.table-container::v-deep .el-scrollbar__bar {
  height: 0px;
  position: relative;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity 120ms ease-out;
  transition: opacity 120ms ease-out;
  /* Your styles for el-scrollbar__view and el-scrollbar__bar go here */
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

th {
  text-align: left;
  /* Center align text horizontally */
  padding: 8px;
  background-color: var(--lighter-primary-color);
  /* Default background color for table heading */
  white-space: nowrap;
  /* Prevent line breaks in table headings */
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-contrast-color);
  /* Add ellipsis (...) for long headings */
}

th.sticky {
  /* Make the specified th elements sticky */
  position: sticky;
  left: 0;
  background-color: #f2f2f2;
  /* You can adjust the background color as needed */
  z-index: 1;
  /* Ensure it appears above other content when scrolling */
}

td {
  border: 1px solid #dddddd;
  text-align: center;
  /* Center align text horizontally */
  padding: 8px;
  white-space: nowrap;
  /* Prevent line breaks in cell content */
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #ffffff;
  /* Add ellipsis (...) for long content */
}

tr:hover:not(:first-child) {
  background-color: #f9f9f9;
  /* Lighter grey color on hover for non-heading rows */
}

.content-table {
  /* Set a fixed table layout */
  table-layout: fixed;
  max-width: 100%;
  min-width: -webkit-fill-available;
}

.newly-added-row {
  background-color: skyblue;
  /* You can adjust the background color as needed */
}

@-moz-document url-prefix() {
  .scrollbar .el-scrollbar__view {
    display: grid;
    /* This is how we can apply browser specific styles by targeting pseudo classess. */
  }
}

.table-container th.sticky {
  position: sticky;
  right: 0;
  background-color: var(--lighter-primary-color);
  z-index: 1;
  width: 2vw;
}

.table-container th.sticky .d-flex {
  flex-direction: column;
  align-items: baseline;
}

.table-container th.sticky .d-flex el-button {
  margin-top: 5px; /* Adjust the margin as needed */
}

.table-container th.sticky .d-flex el-button + el-button {
  margin-top: 5px; /* Additional margin between buttons if needed */
}
.select-check {
  width: 10px;
}

.aligned-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.card-style {
  border-style: solid;
  border-width: thin;
  border-color: rgba(27, 72, 126, 0.1294117647);
  margin-bottom: 20px;
  box-shadow: 0 0px 10px 3px rgba(27, 72, 126, 0.1294117647);
}

.el-button--primary {
  color: var(--lighter-primary-color);
  background-color: var(--primary-contrast-color);
  border-color: var(--primary-color);
}

.el-button--primary:focus,
.el-button--primary:hover {
  color: var(--lighter-primary-color);
  background-color: var(--primary-contrast-color);
  border-color: var(--primary-color);
}

.instruction-button {
  display: flex;
  justify-content: end;
  margin-top: 5px;
}
</style>
